import api from '@/api';
import fileHeader from '@/api/fileHeader';

export const getTreeTypes = async () => {
    const response = await api.get(`/tree-type`);
    const trees = response.data.data;
    return trees;
}

export const createTreeType = async (data) => {
    const response = await api.post('/tree-type', data);
    return response.data.data;
}

export const patchTreeType = async (id, newData) => {
    const response = await api.post(`/tree-type/${id}`, newData);
    return response.data.data;
}

export const removeTreeType = async (id) => {
    const response = await api.delete(`/tree-type/${id}`);
    return response;
}

export const findTree = async (id) => {
    const response = await api.get(`/tree/${id}`);
    const trees = response.data.data;
    return trees;
}

export const getTrees = async () => {
    const response = await api.get('/trees/indexByUserType');
    const trees = response.data.data;
    return trees;
}

export const createTree = async (data) => {
    const response = await api.post('/tree', data, fileHeader);
    return response.data.data;
}

export const patchTree = async (id, newData) => {
    newData.append('_method', 'PUT');

    const response = await api.post(`/tree/${id}`, newData, fileHeader);
    return response.data.data;
}

export const removeTree = async (id) => {
    const response = await api.delete(`/tree/${id}`);
    return response;
}

export const getAvailableTrees = async () => {
    const response = await api.get('/available/trees');
    return response.data.data;
}

export const addTreeToUser = async (ids) => {
    const response = await api.post('/user-tree', ids);
    return response;
}

export const treeStatusList = async () => {
    const response = await api.get('/trees/status');
    return response.data.data;
}

export const badConditionTrees = async() => {
    const badTres = (await api.get('/get-condition-trees')).data?.data?.data || [];
    return badTres.filter(tree => tree.condition == 'fair')
}

export const getTreeInformation = async(id) => {
    const response = await api.get(`trees/all/${id}`);
    return response.data.data;
}

export const garden = async () => {
    const response = await api.get('/services');

    return response.data.data;
} 