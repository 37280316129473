import axios from "axios";
import { setUniversalToken, getUniversalToken } from '@/services/localStorage';

const baseURL = process.env.VUE_APP_URL_LOCATIONS_API;
const token = process.env.VUE_APP_LOCATIONS_TOKEN;
const email = process.env.VUE_APP_LOCATIONS_EMAIL;
const bearerTokenInit = getUniversalToken();

const fetchBearerToken = async () => {
  try {
    const response = await axios.get(`${baseURL}/getaccesstoken`, {
      headers: {
        "user-email": email,
        "api-token": token,
      },
    });
    return response.data.auth_token;
  } catch (error) {
    return "no_token";
  }
};

const api = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${ bearerTokenInit }`,
  },
});


api.interceptors.request.use(async (config) => {
  const bearerToken = getUniversalToken();

  if(bearerToken === '' || bearerToken === 'no_token'){ //before making a request we verify if bearerToken is set
    const brToken = await fetchBearerToken();
    setUniversalToken(brToken);
  }

  const storedBrToken = getUniversalToken();
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${ storedBrToken }`
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});


api.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  const { response } = error;

  if(['TokenExpiredError', 'JsonWebTokenError'].includes(response.data.error.name || '')){ //token may be expired we generate a new one 
    const brToken = await fetchBearerToken();
    setUniversalToken(brToken);

    if(brToken !== 'no_token'){
      api.request(error.config);
    }
  }

  return Promise.reject(error);
});

export default api;
