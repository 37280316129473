import { getUsers } from "@/services/user";

export const getUsersByType = async(context, userType = 'all') => {
  return await getUsers(userType);
}

export const getUserTypes = async () => {
  return [
    { value: 'admin', text: 'Administrador' },
    { value: 'seeder', text: 'Plantador' },
    { value: 'user', text: 'Usuario' }
  ]
}