import api from '@/api';
import { priceString } from '@/helpers/priceFormat';

export const fetchLevel = async(id) => {
    const response = await api.get(`/subscriptionLevel/${id}`);
    return response.data.data;
}

export const saveLevel = async(data) => {
    const response = await api.post ('/subscriptionLevel', data);
    return response.data.data;
}

export const patchLevel = async(id, data) => {
    const response = await api.put(`/subscriptionLevel/${id}`, data);
    return response.data.data;
}

export const removeLevel = async(id) => {
    const response = await api.delete(`/subscriptionLevel/${id}`);
    return response.data;
}

export const saveOption = async(data) => {
    const response = await api.post ('/subscriptionOptions', data);
    return response.data.data;
}

export const patchOption = async (id, newData) => {
    const response = await api.put(`/subscriptionOptions/${id}`, newData);
    return response.data.data;
}

export const removeOption = async (id) => {
    const response = await api.delete(`/subscriptionOptions/${id}`);
    return response.data;
}

export const getSubscriptions = async () => {
    const response = await api.get('/subscriptionLevel/');

    const subscriptions = response.data.data;
    
    return subscriptions.map(subscription => {
        return {
            id: subscription.id,
            urlImage: subscription.url_image,
            name: subscription.name,
            active: subscription.active,
            every: subscription.text_duration,
            qtyDays: subscription.qtyDays,
            cost: priceString(subscription.cost),
            tax: subscription.tax,
            options: getLevelOptions(subscription.has_options_detail, subscription.has_options)
        }
    })
}

export const getOptions = async () => {
    const response = await api.get('/subscriptionOptions/');
    const subscriptions = response.data.data;
    return subscriptions;
}

export const addOptionToLevel = async (subscription_level_id,subscription_option_id) => {
    const response = await api.post('/subscriptionInclude',{subscription_level_id, subscription_option_id});
    return response.data.data;
}
export const deleteOptionFromLevel = async (relationId) => {
    const response = await api.delete(`/subscriptionInclude/${relationId}`);
    return response;
}

const getLevelOptions = (listOfOptions, listOfOptionLevelRelation) => { //includes the relation id of level/option in the option object
    return listOfOptions.map(option => {
        const relationObject = listOfOptionLevelRelation.find(relation => relation.subscription_option_id === option.id);

        return { ...option, relationId: relationObject?.id || null }
    })
}