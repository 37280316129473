import { getRequestList, getRequestTypes, getRequestStatus, findType } from '@/services/joinRequest';

export const requestList = async(context, { type = 'seeder', status = 'pending' } = {}) => {
  return getRequestList(type, status);
}

export const requestTypes = async() => {
  return getRequestTypes();
}

export const requestStatus = async() => {
  return getRequestStatus();
}

export const findTypeObject = async (context, type) => {
  return findType(type);
}
