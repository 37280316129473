
export const SET_SUBSCRIPTIONS = (state, data) => {
    state.subscriptions = data;
 }

/* export const ADD_LEVEL = (state, data) => { 
   state.subscriptions.push(data);
 }*/

 export const SET_OPTIONS = (state, data) => {
    state.options = data;
 }

 export const ADD_OPTION = (state, data) => {
   state.options.push(data);
 }

 export const REPLACE_OPTION = (state, { id, newData }) => {
   const index = state.options.findIndex(option => option.id == id);
   if(index >= 0)
      state.options.splice(index, 1, newData);
}

 export const DELETE_OPTION = (state, id) => {
   const index = state.options.findIndex(option => option.id == id);
   if(index >= 0)
      state.options.splice(index, 1);
}

 export const UPDATE_LEVEL = (state, data) => {
   const levelId = data.levelId;
   const levelIndex = state.subscriptions.findIndex(subscription => {
      return subscription.id == levelId;
   }) 

   if(levelIndex>=0 && data.update){
      state.subscriptions[levelIndex].options.push(data.option);
   }
 }

 export const REMOVE_OPTION_LEVEL = (state, { planId, relationId }) => {
   const levelIndex = state.subscriptions.findIndex(subscription => {
      return subscription.id == planId;
   }) 

   if(levelIndex >= 0 ){
      const subscription = state.subscriptions[levelIndex];
      const optionIndex = subscription.options.findIndex(option => option.relationId === relationId);
      
      if(optionIndex >= 0)
         state.subscriptions[levelIndex].options.splice(optionIndex, 1);
   }
 }