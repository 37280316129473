<template>
  <v-expansion-panels :model="true">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>Jardin online</div>
        <div><v-icon>mdi-thermometer</v-icon><span>{{ firstElement.temperature }} °C</span></div>
        <div><v-icon>mdi-water-percent</v-icon><span>{{ firstElement.humidity }} %</span></div>
        <div v-if="online" @click="$emit('onClick')">
          <v-icon color="green">mdi-circle-small</v-icon><span>Live</span>
        </div>
        <div v-else @click="$emit('onClick')">
          <v-icon color="red">mdi-circle-small</v-icon><span>Go live</span>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div id="trees">
          <div v-for="(s, index) in stats" :key="index">
            <v-row justify="space-between">
              <v-col cols="12">
                <v-row no-gutters justify="space-between">
                    <div><span>Actualización {{ date(s.created_at) }}</span></div>
                    <div><v-icon>mdi-thermometer</v-icon><span>Temperatura {{ s.temperature }} °C</span></div>
                    <div><v-icon>mdi-water-percent</v-icon><span>Humedad {{ s.humidity }} %</span></div>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { getFullDate } from '@/domain/DateFormarter';

export default {
  name: "TreeAlerts",

  props: {
    stats: {
      type: Array,
      required: true,
    },

    online: Boolean,
  },

  methods: {
    date(date){
        return getFullDate(date)
    }
  },

  computed: {
    firstElement(){
        if(this.stats.length > 0)
            return this.stats[0];
        else 
            return null;
    }
  }
};
</script>
