import { setUserToken } from "@/services/localStorage";

export const SET_USER = (state, data) => {
    setState(state, data);
}

export const UNSET_USER = (state) => {
    setState(state, {});
}

export const SET_IS_AUTH_READY = (state, status) => {
    state.authReady = status;
}

const setState = (state, data) => {
    const token =  data.token || '';
    
    setUserToken(token);
    state.token = data.token || null;
    state.userId = data.id || null;
    state.name = data.name || '';
    state.email = data.email || '';
    state.role = data.role[0] || '';
}