<template>
<v-footer
  padless
>
    <v-card
      width="100%"
      flat
      tile
      class="primary lighten-1 white--text"
    >
      <v-card-text class="white--text text-center" >
        {{ new Date().getFullYear() }} © <span>Plantemos un árbol</span>
        <br>
        Zapopan Jalisco, México
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>

  export default {
    name: 'AppFooter',
  }
</script>