<template>
    <GChart
      type="ColumnChart"
      :data="chartData" 
      :options="chartOptions" 
    />
  </template>
  
  <script>
  import { GChart } from 'vue-google-charts/legacy';

  export default {
    components: { GChart },

    props: {
      chartData: {
        type: Array,
        required: true,
      }
    },

    data () {
      return {
        chartOptions: {
          title: 'Estado de árboles plantados',
        }
      }
    }
  }
  </script>
  