<template>
  <v-app-bar
    app
    color="rgba(255, 255, 255, 1)"
    flat
  >
    <v-row no-gutters align="center">
      <app-logo v-if="!showDrawer"/>
      <!-- <app-logo class="d-md-none d-block"/> -->
      <v-spacer />
      <auth-control />
      <v-app-bar-nav-icon @click="showDrawer = !showDrawer"></v-app-bar-nav-icon>
    </v-row>
  </v-app-bar>
</template>

<script>
import AppLogo from '@/components/AppLogo';
import AuthControl from '@/components/AuthControl';

export default {
  name: 'NavHeader',

  components: {
    AppLogo,
    AuthControl,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    }
  },

  computed: {
    showDrawer:{
      get(){
        return this.value;
      },
      set(newValue){
        this.$emit('input', newValue);
      }
    }
  }
}
</script>

<style scoped>
.animated-link {
  transition: ease-in-out 0.3s;
  padding: 5px 10px 5px 10px;
}
.animated-link:hover {
  background: #e1eee4;
  border-radius: 5px;
  padding: 5px 20px 5px 20px;
  transition: ease-in-out 0.5s;
}
</style>
