import Crypter from '@/domain/crypter';
const crypter = new Crypter();

export const setUserToken = (token) => {
    localStorage.setItem('userId', token);
}

export const getUserToken = () => {
    const token = localStorage.getItem('userId');
    return token === '' || undefined ?  null : token;
}

export const setPasswordRecoveryEmail = (email) => {
    localStorage.setItem('recovery-email', crypter.encryp(email));
}

export const getPasswordRecoveryEmail = () => {
    const emailEncripted = localStorage.getItem('recovery-email');
    const email = crypter.decrypt(emailEncripted);

    return email === '' ?  null : email;
}

export const setUniversalToken = (token) => {
    localStorage.setItem('universal_api_token', crypter.encryp(token));
}

export const getUniversalToken = () => {
    const tokenEncripted = localStorage.getItem('universal_api_token');
    const token = crypter.decrypt(tokenEncripted);

    return token;
}