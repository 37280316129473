import api from '@/api';

export const getRequestList = async (type, status) => {
    const statusEnum = getStatusEnum(status);
    const typeEnum = getTypeEnum(type);
    const response = await api.get(`/form?type=${typeEnum}&status=${statusEnum}`);

    return response.data.data;
}

export const getRequestTypes = () => {
    return [
        { enum: 1, name: 'sponsor', text: 'Patrocinador' },
        { enum: 2, name: 'seeder', text: 'Plantador' },
    ]
}

export const getRequestStatus = () => {
    return [
        { enum: 1, name: 'pending', text: 'Por revisar', },
        { enum: 2, name: 'checking', text: 'En revisión' },
        { enum: 10, name: 'accepted', text: 'Aceptado' }
    ]
}

export const findStatus = (status) => {
    return getRequestStatus().find(( s ) => s.name === status);
}

export const findType = (type) => {
    return getRequestTypes().find(( s ) => s.name === type);
}

export const getStatusEnum = (status) => {
    return findStatus(status)?.enum
}

export const getTypeEnum = (type) => {
    return findType(type)?.enum
}

