import api from '@/api';

export const getUsers = async(userType = 'all') => {
    const response = await api.post(`/getUsers?userType=${userType}`);
    return response.data.data;
}

export const getUserToAssignTree = async() => {
    const response = await api.get('/users/available/balance/');
    return response.data.data;
}
