<template>
  <v-overlay :value="value">
    <v-progress-circular
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
  export default {
    name: 'AppOverlay',

    props: {
      value: {
        type: Boolean,
        required: true,
      }
    },
  }
</script>
