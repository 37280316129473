export const SET_TREE_STATUS = (state, data) => {
   state.treeStatus = data;
}

export const SET_TREE_TYPES = (state, data) => {
   state.treeTypeList = data;
}

export const ADD_TREE_TYPE = (state, data) => {
   state.treeTypeList.unshift(data);
}

export const REPLACE_TREE_TYPE = (state, { id, newData }) => {
   const index = state.treeTypeList.findIndex(tree => tree.id == id);
   if(index >= 0)
      state.treeTypeList.splice(index, 1, newData);
}

export const DELETE_TREE_TYPE = (state, id) => {
   const index = state.treeTypeList.findIndex(tree => tree.id == id);
   if(index >= 0)
      state.treeTypeList.splice(index, 1);
}

export const SET_TREES = (state, data) => {
   state.trees = data;
}

export const ADD_TREE = (state, data) => {
   state.trees.unshift(data);
}

export const REPLACE_TREE = (state, { id, newData }) => {
   const index = state.trees.findIndex(tree => tree.id == id);
   if(index >= 0)
      state.trees.splice(index, 1, newData);
}

export const DELETE_TREE = (state, id) => {
   const index = state.trees.findIndex(tree => tree.id == id);
   if(index >= 0)
      state.trees.splice(index, 1);
}

