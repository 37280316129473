export const getSimpleCapaign = (campaign) => {
  const { id, name, location_id, detail, status, date_start, date_end } = campaign;

  return {
    id,
    name,
    location_id,
    detail,
    date_start,
    date_end,
    status
  };
};
