import api from '@/api';
import { getSimpleCapaign } from '@/domain/Campaign';

export const getUserCampaigns = async () => {
    const response = await api.get('/campaing/user');
    return response.data.data.campaigns;
}

export const createCampaing = async (data) => {
    const response = await api.post('/campaign', data);
    return response.data.campaign;
}

export const patchCampaign = async (id, newData) => {
    const response = await api.put(`/campaign/${id}`, newData);
    return response.data.campaign;
}

export const fetchCampaign = async (id, simple) => {
    const response = await api.get(`/campaign/${id}`);
    const campaign = response.data.data;

    return simple ? getSimpleCapaign(campaign) : campaign;
}

export const removeCampaign = async (id) => {
    const response = await api.delete(`/campaign/${id}`);
    return response;
}
