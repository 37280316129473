import { register, registerManualUser,login, logout, passwordRecovery, getUserSession, passwordReset } from '@/services/auth'
import { getUserToken, getPasswordRecoveryEmail, setPasswordRecoveryEmail } from '@/services/localStorage';

export const signup = async (context, credentials) => {
        const response = await register(credentials);
        const user = {...response.data.user, token: response.data.token};
        context.commit('SET_USER', user);

        return user;
}

export const addUser = async (context, credentials) => {
        const response = await registerManualUser(credentials);
        const user = response.data.user ;
        return user;
}

export const signIn = async (context, credentials) => {
        const response = await login(credentials);
        const user = {...response.data.user, token: response.data.token};
        context.commit('SET_USER', user);

        return user;
}

export const signOut = async (context) => {
        const response = await logout();
        context.commit('SET_USER', {});
        return response;
}

export const forgotPassword = async (context, credentials) => {
        const response = await passwordRecovery(credentials);
        setPasswordRecoveryEmail(credentials.email);
        return response;
}

export const resetPassword = async (context, credentials) => {
        const response = await passwordReset(credentials);
        const user = {...response.data.user, token: response.data.token};
        context.commit('SET_USER', user);
        setPasswordRecoveryEmail('');
        return response;
}

export const clearRecoveryEmail = () => {
        setPasswordRecoveryEmail('');
        return;
}

export const getForgotPasswordEmail = () => {
        return getPasswordRecoveryEmail();
} 

export const recoverUserSession = async(context) => {
        const userToken = getUserToken();
        try {
                if(userToken !== null){
                        const user = await getUserSession(userToken);
                        context.commit('SET_USER', user);
                }
        } catch (error) {
                context.commit('SET_USER', {});
                return
        }

        return;
}

export const verifyUserSession = async(context) => {
        const userToken = getUserToken();
        try {
                if(userToken === null && context.state.token !== null){
                        context.commit('SET_USER', {});
                }
                else if(userToken !== null && userToken !== context.state.token){
                        const user = await getUserSession(userToken);
                        context.commit('SET_USER', user);
                } 
        } catch (error) {
                context.commit('SET_USER', {});
        }

        return;
}

export const validateSession = async(context) => {
        const userToken = getUserToken();
        try {
                if(userToken !== null) {
                        const user = await getUserSession(userToken);
                        if(user.email !== context.state.email) {
                                context.commit('SET_USER', user);
                        }
                        return true;
                } 
                return false;
        } catch (error) {
                context.commit('SET_USER', {});
                return false;
        }
}
