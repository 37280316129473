class TreeList {
    getNumberOfTreesByState(treeList, states) {
        if(treeList.length == 0)
            return [];

        const header = ['Estado', 'No. Árboles'];

        const trees = states.map(state => {
            const numberOfTrees = treeList.filter(({ status }) => state.id == status.id).length;
            return [state.text, numberOfTrees];
        });

        return [ header, ...trees];
    }

    getNumberOfTreesByCampaign(campaignsWithTreesCount){
        if(campaignsWithTreesCount.length == 0)
            return [];

        const header = ['Campaña', 'No. Árboles'];

        const campaigns = campaignsWithTreesCount.map(campaign => {
            return [campaign.name, campaign.trees.count];
        });

        return [header, ...campaigns]

    }
}

export default new TreeList()