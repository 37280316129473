<template>
    <GChart 
        type="PieChart"
        :data="chartData" 
        :options="chartOptions" 
    />
  </template>
  
  <script>
  import { GChart } from 'vue-google-charts/legacy';

  export default {
    components: { GChart },

    props: {
      chartData: {
        type: Array,
        required: true,
      }
    },

    data () {
      return {
        // chartData: [
        //   ['Estado', 'No. Árboles'],
        //   ['Bueno', 100],
        //   ['Malo', 1170],
        //   ['Regular', 660],
        // ],
        chartOptions: {
          title: 'Porcentaje de árboles plantados por campaña',
        }
      }
    }
  }
  </script>
  