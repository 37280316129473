import { getStates, getCountries, getCities, createLocation, getUserLocations, removeLocation, patchLocation } from "@/services/location";

export const setCountries = async(context) => {
  if (context.state.countries.length <= 0) {
		const response = await getCountries();
    context.commit("SET_COUNTRIES", response);
	}
}

export const setLocationsList = async(context) => {
  if(context.state.locations.length <= 0) {
    const response = await getUserLocations();
    context.commit('SET_LOCATIONS', response);
  }
}

export const getCountryStates = async (context, country = 'mexico') => {
  if(context.state.lastCountryStatesFetch === country) //avoid call to already stored country states
    return context.state.states;
  else {
    const states = await getStates(country);
    context.commit('SET_COUNTRY_STATES', { country, states });
    return states;
  }
}

export const getStateCities = async (context, state) => {
  const response = await getCities(state);
  return response;
}

export const saveLocation = async(context, data) => {
  const newLocation = await createLocation(data);
  context.commit('ADD_LOCATION', newLocation);
  return newLocation;
}

export const deleteLocation = async(context, id) => {
  const response = await removeLocation(id);
  context.commit('DELETE_LOCATION', id);
  return response;
}

export const updateLocation = async(context, { newData, id }) => {
  const updatedLocation = await patchLocation(id, newData);
  context.commit('REPLACE_LOCATION', { id, newData: updatedLocation });
  return updatedLocation;
}