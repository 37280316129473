export const SET_SPONSORS = (state, data) => {
   state.sponsors = data;
}

export const ADD_SPONSOR = (state, data) => {
   state.sponsors.unshift(data);
}

export const REPLACE_SPONSOR = (state, { id, newData }) => {
   const index = state.sponsors.findIndex(tree => tree.id == id);
   if(index >= 0)
      state.sponsors.splice(index, 1, newData);
}

export const DELETE_SPONSOR = (state, id) => {
   const index = state.sponsors.findIndex(tree => tree.id == id);
   if(index >= 0)
      state.sponsors.splice(index, 1);
}
