import { treeStatusList, getTreeTypes, createTreeType, removeTreeType, patchTreeType, getTrees, findTree, createTree, removeTree, patchTree, getAvailableTrees, addTreeToUser, badConditionTrees } from "@/services/tree";
import { getUserToAssignTree } from "@/services/user";

export const setTreeStatus = async (context) => {
  if (context.state.treeStatus.length <= 0) {
    const response = await treeStatusList();
    context.commit("SET_TREE_STATUS", response);
  }
};

export const setTreeTypeList = async (context) => {
  if (context.state.treeTypeList.length <= 0) {
    const response = await getTreeTypes();
    context.commit("SET_TREE_TYPES", response);
  }
};

export const saveTreeType = async (context, data) => {
  const newTreeType = await createTreeType(data);
  context.commit("ADD_TREE_TYPE", newTreeType);
  return newTreeType;
};

export const updateTreeType = async (context, { newData, id }) => {
  const updatedTree = await patchTreeType(id, newData);
  context.commit("REPLACE_TREE_TYPE", { id, newData: updatedTree });
  return updatedTree;
};

export const deleteTreeType = async (context, id) => {
  const response = await removeTreeType(id);
  context.commit("DELETE_TREE_TYPE", id);
  return response;
};

export const setTreeList = async (context) => {
  if (context.state.trees.length <= 0) {
    const response = await getTrees();
    context.commit("SET_TREES", response);
  }
};

export const getTree = async (context, id) => {
  return await findTree(id);
}

export const saveTree = async (context, data) => {
  const newTree = await createTree(data);
  context.commit("ADD_TREE", newTree);
  return newTree;
};

export const updateTree = async (context, { newData, id }) => {
  const updatedTree = await patchTree(id, newData);
  context.commit("REPLACE_TREE", { id, newData: updatedTree });
  return updatedTree;
};

export const deleteTree = async (context, id) => {
  const response = await removeTree(id);
  context.commit("DELETE_TREE", id);
  return response;
};

export const getResourcesFreeToAssign = async () => {
  const users = await getUserToAssignTree();
  const trees = await getAvailableTrees();

  return { users, trees }
}

export const createAssign = async (context, ids) => {
  const response = await addTreeToUser(ids);
  return response;
}

export const getBadConditionTrees = async () => {
  return await badConditionTrees()
}
