<template>
    <div>
        <v-list-item color="secondary" link :to="url" @click="$emit('click')">
            <v-list-item-icon v-if="icon">
                <v-icon right>{{icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title class="primary--text">{{title}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </div>
</template>
<script>

export default {
    name: 'listItem',

    props:['title', 'icon', 'url']
}
</script>
