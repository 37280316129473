import Vue from 'vue'
import Vuex from 'vuex'
import Auth from './auth'
import Sponsor from './sponsor'
import Tree from './tree'
import Subscription from './subscription'
import Location from './location'
import Campaign from './campaign'
import JoinRequest from './joinRequest'
import User from './user'
 
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Auth,
    Sponsor,
    Tree,
    Subscription,
    Location,
    Campaign,
    JoinRequest,
    User
  }
})
