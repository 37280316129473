import { createCampaing, removeCampaign, patchCampaign, getUserCampaigns, fetchCampaign } from "@/services/campaign";

export const setCampaignsList = async(context) => {
  if(context.state.campaigns.length <= 0) {
    const response = await getUserCampaigns();
    context.commit('SET_CAMPAIGNS', response);
  }
}

export const getCampaign = async(context, id) => {
  return await fetchCampaign(id, true);
}

export const getCampaignWithRelations = async(context, id) => {
  return await fetchCampaign(id, false);
}

export const saveCampaign = async(context, data) => {
  const newCampaign = await createCampaing(data);
  context.commit('ADD_CAMPAIGN', newCampaign);
  return newCampaign;
}

export const deleteCampaign = async(context, id) => {
  const response = await removeCampaign(id);
  context.commit('DELETE_CAMPAIGN', id);
  return response;
}

export const updateCampaign = async(context, { newData, id }) => {
  const updatedCampaing = await patchCampaign(id, newData);
  context.commit('REPLACE_CAMPAIGN', { id, newData: updatedCampaing });
  return updatedCampaing;
}