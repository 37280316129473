import CryptoJS from 'crypto-js';

class Crypter {
    constructor(){
        this.secretKey = process.env.VUE_APP_SECRET_KEY
    }

    encryp = (text) => {
        if(text === null || text === ''){
            return ''
        }
        try {
            return CryptoJS.AES.encrypt(text, this.secretKey).toString();
        } catch (error) {
            return '';            
        }
    }

    decrypt = (text) => {
        if(text === null || text === ''){
            return ''
        }
        try {
            const bytes = CryptoJS.AES.decrypt(text, this.secretKey);
            return bytes.toString(CryptoJS.enc.Utf8);
        } catch (error) {
            return ''
        }
    }
}

export default Crypter;