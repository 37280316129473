import { 
        getSubscriptions, 
        fetchLevel,  
        saveLevel,
        patchLevel, 
        removeLevel,
        getOptions, 
        saveOption,
        patchOption, 
        removeOption, 
        addOptionToLevel,
        deleteOptionFromLevel
} from '@/services/subscriptions'

export const getLevel = async (context, id) => {
        const level = await fetchLevel(id);
        return level;
}

export const storeLevel = async (context,data) => {
        const newLevel = await saveLevel(data);
        await context.dispatch('setSubscriptions', true);
        return newLevel;
}

export const updateLevel = async (context, { id, newData }) => {
        const updatedLevel = await patchLevel(id, newData);
        await context.dispatch('setSubscriptions', true);

        return updatedLevel;
}

export const deleteLevel = async (context, id) => {
        const updatedLevel = await removeLevel(id);
        await context.dispatch('setSubscriptions', true);

        return updatedLevel;
}

export const storeOption = async ( context, data) =>{
        const newOption = await saveOption(data);
        context.commit('ADD_OPTION', newOption)
        return newOption;
}

export const updateOption = async ( context, { newData, id }) =>{
        const opdatedOption = await patchOption(id, newData);
        context.commit('REPLACE_OPTION', { id, newData: opdatedOption });
        return opdatedOption;
}

export const deleteOption = async ( context, id) =>{
        const response = await removeOption(id);
        context.commit('DELETE_OPTION', id);
        return response;
}

export const setOptions = async ( context ) =>{
        if(context.state.options.length <= 0){
        const response = await getOptions();
        context.commit('SET_OPTIONS', response);
        }
        return
}

export const setSubscriptions = async (context, force=false) => {
        if(context.state.subscriptions.length <= 0 || force){
          const response = await getSubscriptions();
          context.commit('SET_SUBSCRIPTIONS', response);
        }
      
        return
}

export const validateLevelJoin = (context, {level,option}) => {
        const hasOption = level.options.findIndex(optionAux=>{
                return optionAux.id == option.id;
        })
        return hasOption < 0;
}

export const addOptionToLvl = async (context, data) =>{
        const response = await addOptionToLevel(data.level.id,data.option.id);
        const option = { ...data.option, relationId: response.id }; 
        
        context.commit("UPDATE_LEVEL", {levelId:data.level.id, option, update:true});
        return response;
}

export const removeOptionFromLevel = async (context, data) =>{
        const response = await deleteOptionFromLevel(data.relationId);
        context.commit("REMOVE_OPTION_LEVEL", data);
        return response;
}