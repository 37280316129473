<template>
  <v-list>
    <v-list-group :value="true" prepend-icon="mdi-account-circle">
      <template v-slot:activator>
        <v-list-item-title>Admin</v-list-item-title>
      </template>

      <v-list-group no-action sub-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Usuarios</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item to="/users-list">
          <v-list-item-title>Lista</v-list-item-title>
        </v-list-item>

        <v-list-item to="/add-user">
          <v-list-item-title>Nuevo</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-list-group no-action sub-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Suscripciones</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item to="/levels">
          <v-list-item-title>Niveles</v-list-item-title>
        </v-list-item>

        <v-list-item to="/levels-create">
          <v-list-item-title>Nuevo nivel</v-list-item-title>
        </v-list-item>

        <v-list-item to="/options">
          <v-list-item-title>Opciones</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-list-group no-action sub-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Plantadores</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item :to="{name: 'usersList', query: { userType: 'seeder'}}">
          <v-list-item-content>
            <v-list-item-title>Lista</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/seeders-request">
          <v-list-item-content>
            <v-list-item-title>Solicitudes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
     
      <v-list-group no-action sub-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Patrocinadores</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item to="/sponsors">
          <v-list-item-content>
            <v-list-item-title>Lista</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/sponsors-request">
        <v-list-item-content>
            <v-list-item-title>Solicitudes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-group no-action sub-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Arboles</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item to="/tree-assign">
          <v-list-item-content>
            <v-list-item-title>Asignar árbol</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/trees-type">
          <v-list-item-content>
            <v-list-item-title>Tipos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list-group>


    </v-list-group>
  </v-list>
</template>

<script>
export default {
  name: "AdminOptions",

  data() {
    return {
      admins: [
        ["Management", "mdi-account-multiple-outline"],
        ["Settings", "mdi-cog-outline"],
      ],
      cruds: [
        ["Nuevo", "mdi-plus-outline"],
        ["Ver lista", "mdi-file-outline"],
      ],
    };
  },
};
</script>
