<template>
  <v-expansion-panels :model="true">
    <v-expansion-panel>
      <v-expansion-panel-header>Árboles que requieren atención</v-expansion-panel-header>
      <v-expansion-panel-content>
        <div id="trees">
          <v-alert
            v-for="(t, index) in trees"
            :key="index"
            dense
            outlined
            type="error"
          >
            <v-row justify="space-between">
              <v-col cols="7">
                <span>{{ t.tree.id}} - {{ t.tree.name}} | {{ t.tree.sientific_name }}</span>
              </v-col>
              <v-col cols="auto">
                <v-btn small text :to="{ name: 'treeDetails', params:{ id: t.tree.id }}">
                  Ver
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </div>
        <div>
          <p><small>Estas alertas son basadas en nuestros registros, ya que los árboles listados en este apartado es común que puedan terminar en un estado no favorable, es por eso que recomendamos prestarles más atención.</small></p>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'TreeAlerts',

  props: {
    trees: {
      type: Array,
      required: true,
    }
  },

  methods: {
    getAlertType(condition){
      if(condition == 'fair')
        return 'error';
      else 
        return 'warning';
    },

    goToTree(treeId){
      this.$router.push({ name: 'treeDetails', params:{ id: treeId }})
    }
  }
}
</script>