export const SET_COUNTRIES = (state, data) => {
   state.countries = data;
}

export const SET_COUNTRY_STATES = (state, { country, states } ) => {
   state.lastCountryStatesFetch = country;
   state.states = states;
}

export const SET_LOCATIONS = (state, data) => {
   state.locations = data;
}

export const ADD_LOCATION = (state, data) => {
   state.locations.unshift(data);
}

export const DELETE_LOCATION = (state, id) => {
   const index = state.locations.findIndex(location => location.id == id);
   if(index >= 0)
      state.locations.splice(index, 1);
}

export const REPLACE_LOCATION = (state, { id, newData }) => {
   const index = state.locations.findIndex(location => location.id == id);
   if(index >= 0)
      state.locations.splice(index, 1, newData);
}