<template>
  <v-app>
    <app-header v-model="showDrawer" v-if="!hideNav" />
    <app-drawer v-model="showDrawer" v-if="!hideNav" />
    <v-main class="mb-5 mb-md-0">
      <router-view/>
    </v-main>
    <app-footer v-if="!hideFooter"/>
  </v-app>
</template>

<script>
import AppHeader from '@/layout/AppHeader';
import AppFooter from '@/layout/AppFooter';
import AppDrawer from './layout/AppDrawer.vue';

export default {
  name: 'App',

  components: {
    AppHeader,
    AppFooter,
    AppDrawer
  },

  data: () => ({
    showDrawer: true,
  }),

  computed: {
    hideNav() {
      return this.$route.meta.hideNav;
    },
    
    hideFooter() {
      return this.$route.meta.hideFooter;
    },

    // isMobile() {
    //     switch (this.$vuetify.breakpoint.name) {
    //       case 'xs': return true
    //       case 'sm': return true
    //       default: return false
    //     }
    // }
  }
};
</script>

<style >
@import '@/assets/css/global.css';
</style>