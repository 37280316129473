import universalApi from '@/api/universalApi';
import api from '@/api';

export const getCountries = async () => {
    try {
        const response = await universalApi.get(`/countries`);
        const countries = response.data;

        return countries.map( country => country.country_name );
    } catch (error) {
        return [];        
    }
}

export const getStates = async (country) => {
    try {
        const response = await universalApi.get(`/states/${country}`);
        const states = response.data;
        return states.map( state => state.state_name);
    } catch (error) {
        return [];        
    }
}

export const getCities = async (state) => {
    try {
        const response = await universalApi.get(`/cities/${state}`);
        const cities = response.data;
        return cities.map( city => city.city_name);
    } catch (error) {
        return [];        
    }
}

export const createLocation = async (data) => {
    const response = await api.post('/location', data);
    return response.data.data.location;
}

export const removeLocation = async (id) => {
    const response = await api.delete(`/location/${id}`);
    return response;
}

export const patchLocation = async (id, newData) => {
    const response = await api.put(`/location/${id}`, newData);
    return response.data.data.location;
}

export const getUserLocations = async (data) => {
    const response = await api.get('/userLocations', data);
    return response.data.data;
}

