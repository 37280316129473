import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { requiresAuth: true},
    component: HomeView
  },
  {
    path: '/results',
    name: 'results',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/Results.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: { guest: true, hideNav: true, hideFooter: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    meta: { guest: true, hideNav: true, hideFooter: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    meta: { guest: true, hideNav: true, hideFooter: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/ForgotPassword.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    meta: { requiresAuth: true, hideNav: true, hideFooter: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/Logout.vue')
  },
  {
    path: '/subscriptions',
    name: 'subscriptions',
    meta: { requiresAuth: true, onlyAdmin: true },
    component: () => import ('../views/SubscriptionView.vue')
  },
  {
    path: '/levels',
    name: 'levels',
    meta: {requiresAuth: true, onlyAdmin: true},
    component: () => import ('../views/subscriptions/LevelsView.vue')
  },
  {
    path: '/levels-create',
    name: 'levelsCreate',
    meta: {requiresAuth: true, onlyAdmin: true},
    component: () => import ('../views/subscriptions/LevelCreate.vue')
  },
  {
    path: '/levels-edit/:id',
    name: 'levelsEdit',
    meta: {requiresAuth: true , onlyAdmin: true},
    component: () => import ('../views/subscriptions/LevelEdit.vue')
  },
  {
    path: '/options',
    name: 'options',
    meta: {requiresAuth: true, onlyAdmin: true},
    component: () => import ('../views/subscriptions/OptionView.vue')
  },
  {
    path: '/options-create',
    name: 'optionsCreate',
    meta: {requiresAuth: true},
    component: () => import ('../views/subscriptions/OptionCreate.vue')
  },
  {
    path: '/my-addresses',
    name: 'myAddress',
    meta: { requiresAuth: true },
    component: () => import ('../views/AddressIndex.vue')
  },
  {
    path: '/my-trees',
    name: 'my-trees',
    meta: { requiresAuth: true },
    component: () => import ('../views/TreeIndex.vue')
  },
  {
    path: '/tree/new',
    name: 'newTree',
    meta: { requiresAuth: true },
    component: () => import ('../views/TreeCreate.vue')
  },
  {
    path: '/details/tree/:id',
    name: 'treeDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/TreeDetails.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/tree/edit/:id',
    name: 'editTree',
    meta: { requiresAuth: true },
    component: () => import ('../views/TreeEdit.vue')
  },
  {
    path: '/my-campaigns',
    name: 'campagins',
    meta: { requiresAuth: true },
    component: () => import ('../views/CampaignIndex.vue')
  },
  {
    path: '/campaign/new',
    name: 'newCampaign',
    meta: { requiresAuth: true },
    component: () => import ('../views/CampaignCreate.vue')
  },
  {
    path: '/campaign/edit/:id',
    name: 'editCampaign',
    meta: { requiresAuth: true },
    component: () => import ('../views/CampaignEdit.vue')
  },
  {
    path: '/seeders-request',
    name: 'seedersRequest',
    meta: { requiresAuth: true, requestType: 'seeder', onlyAdmin: true },
    component: () => import ('../views/RequestIndex.vue')
  },
  {
    path: '/sponsors-request',
    name: 'sponsorsRequest',
    meta: { requiresAuth: true, requestType: 'sponsor', onlyAdmin: true },
    component: () => import ('../views/RequestIndex.vue')
  },
  {
    path: '/add-user',
    name: 'addUser',
    meta: { requiresAuth: true, onlyAdmin: true },
    component: () => import ('../views/AddNewUser.vue')
  },
  {
    path: '/users-list',
    name: 'usersList',
    meta: { requiresAuth: true, onlyAdmin: true },
    component: () => import ('../views/UserIndex.vue')
  },
  {
    path: '/trees-type',
    name: 'treesType',
    meta: { requiresAuth: true, onlyAdmin: true },
    component: () => import ('../views/TreeTypeIndex.vue')
  },
  {
    path: '/sponsors',
    name: 'sponsors',
    meta: { requiresAuth: true, onlyAdmin: true },
    component: () => import ('../views/SponsorsIndex.vue')
  },
  {
    path: '/tree-assign',
    name: 'treeAssign',
    meta: { requiresAuth: true, onlyAdmin: true },
    component: () => import ('../views/TreeAssign.vue')
  },
  {
    path: '/*',
    name: 'noFound',
    meta: {},
    component: () => import ('../views/NotFound.vue')
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  //detect token change manually left
  await store.dispatch('Auth/verifyUserSession');
  
  if(!to.meta.guest && !to.meta.requiresAuth){
    next();
  } else{

    await store.dispatch('Auth/validateSession');
    const isAuthenticated = store.getters['Auth/isUserLoggedIn'];
    const isAdmin = store.getters['Auth/isAdmin'];

    if(to.meta.requiresAuth && !isAuthenticated)
      next({ name: 'login' })
    else if(to.meta.guest && isAuthenticated)
      next({name: 'home'});
    else if(to.meta.onlyAdmin && !isAdmin)
      next({name: 'home'})
    else
      next();
  }
})

export default router
