export const SET_CAMPAIGNS = (state, data) => {
   state.campaigns = data;
}

export const ADD_CAMPAIGN = (state, data) => {
   if(state.campaigns.length > 0) //if campaigns has not been fetched we cant add the new campaign
      state.campaigns.unshift(data);
}

export const DELETE_CAMPAIGN = (state, id) => {
   const index = state.campaigns.findIndex(campaign => campaign.id == id);
   if(index >= 0) 
      state.campaigns.splice(index, 1);
}

export const REPLACE_CAMPAIGN = (state, { id, newData }) => {
   const index = state.campaigns.findIndex(campaign => campaign.id == id);
   if(index >= 0)
      state.campaigns.splice(index, 1, newData);
}