<template>
  <v-navigation-drawer
    app
    v-model="showDrawer"
    color="white"
    class="elevation-1"
    mobile-breakpoint="960"
  >
    <v-row no-gutters class="ml-2">
      <app-logo class="d-none d-md-block"/>
    </v-row> 

    <v-list class="grow" dense nav>
      <v-list-item-group color="secondary">
        <list-item title="Inicio" icon="mdi-home" url="/"/>
        <list-item title="Campañas" icon="mdi-watering-can" url="/my-campaigns" class="my-2"/>
        <list-item title="Mis árboles" icon="mdi-tree" url="/my-trees"/>
        <!-- <list-item title="Te ayudo a plantar" icon="mdi-handshake" url="/help-me-to-plant" class="my-2"/> -->
        <list-item title="Mis direcciones" icon="mdi-map-marker" url="/my-addresses" class="my-2"/>
      </v-list-item-group>
    </v-list> 

    <div v-if="isAdmin">
      <v-divider />
      <admin-options />
    </div>

    <template v-slot:append>
      <v-btn block to="/faq" url="/" text color="black">Términos y condiciones</v-btn>
    </template>
  </v-navigation-drawer>
</template>

<script>
  import { mapGetters } from 'vuex';

  import ListItem from '@/components/ListItem';
  import AppLogo from '@/components/AppLogo';
  import AdminOptions from './AdminOptions.vue';

  export default {
    name: 'AppDrawer',
    
    components: { 
      ListItem,
      AppLogo,
      AdminOptions
    },

    props: {
      value: {
        type: Boolean,
        required: true,
      }
    },

    methods: {
    },

    computed:{
      ...mapGetters('Auth', ['isAdmin']),

      showDrawer:{
        get(){
          return this.value;
        },
        set(newValue){
          this.$emit('input', newValue);
        }
      }
    }
  }
</script>

<style>
  .v-navigation-drawer__border {
    width: 0px !important;
  }
</style>
