export const treeTypeList = (state) => {
    return state.treeTypeList;
};

export const treeList = (state) => {
    return state.trees;
};

export const treeStatusList = (state) => {
    return state.treeStatus;
};
