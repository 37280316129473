import api from '@/api';
import fileHeader from '@/api/fileHeader';

export const getSponsors = async () => {
    const response = await api.get(`/sponsor`);
    const trees = response.data.data;
    return trees;
}

export const createSponsor = async (data) => {
    const response = await api.post('/sponsor', data, fileHeader);
    return response.data.data;
}

export const patchSponsor = async (id, newData) => {
    //method sent in newData
    const response = await api.post(`/sponsor/${id}`, newData, fileHeader);
    return response.data.data;
}

export const removeSponsor = async (id) => {
    const response = await api.delete(`/sponsor/${id}`);
    return response;
}



