<template>
  <router-link to="/">
    <v-img src="../assets/img/logo/pua-logo.svg" height="60px" width="80px" contain alt="PUA Logo"/>
  </router-link>
</template>

<script>
export default {
  name: 'AppLogo'
}
</script>