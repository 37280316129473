import { getSponsors, createSponsor, patchSponsor, removeSponsor } from "@/services/sponsor";

export const setSponsorList = async (context) => {
  if (context.state.sponsors.length <= 0) {
    const response = await getSponsors();
    context.commit("SET_SPONSORS", response);
  }
  return;
};

export const saveSponsor = async (context, data) => {
  const newSponsor = await createSponsor(data);
  context.commit("ADD_SPONSOR", newSponsor);
  return newSponsor;
};

export const updateSponsor = async (context, { newData, id }) => {
  const updatedSponsor = await patchSponsor(id, newData);
  context.commit("REPLACE_SPONSOR", { id, newData: updatedSponsor });
  return updatedSponsor;
};

export const deleteSponsor = async (context, id) => {
  const response = await removeSponsor(id);
  context.commit("DELETE_SPONSOR", id);
  return response;
};
