<template>
  <v-container fluid>
    <div v-if="empty">
      <v-alert
        dense
        outlined
        type="warning"
      >
        Comienza a registrar árboles para obtener estadísticas
      </v-alert>
    </div>
    <div>
      <v-row>
        <v-col cols="12" md="6" v-if="treesByState.length > 0">
          <trees-column-chart :chart-data="treesByState" />
        </v-col>
        <v-col cols="12" md="6"  v-if="treesByCampaign.length > 0">
          <campaign-pie-chart :chart-data="treesByCampaign"/>
        </v-col>
        <v-col cols="12" v-if="garden.length > 0">
          <garden-stats :stats="garden" @onClick="updateGarden" :online="gardenIntervalId !== null"/>
        </v-col>
      </v-row>
      <tree-alerts class="mt-5" :trees="trees" v-if="trees.length > 0"/>
    </div>

    <overlay v-model="loading"></overlay>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import treeList from '@/domain/TreeList';
import { garden } from '@/services/tree';

import TreeAlerts from '@/components/TreeAlerts';
import CampaignPieChart from '@/components/charts/CampaignPie.vue';
import TreesColumnChart from '@/components/charts/TreeColumn.vue';
import GardenStats from '@/components/GardenStats.vue';
import Overlay from '@/components/Overlay.vue';

export default {
  name: 'HomePua',

  components: {
    TreeAlerts,
    CampaignPieChart,
    TreesColumnChart,
    GardenStats,
    Overlay
  },

  data(){
    return {
      trees: [],
      treesByState: [],
      treesByCampaign: [],
      garden: [],
      gardenIntervalId: null,

      loading: false,
    }
  },

  async created(){
    try {
      this.loading = true;

      this.trees = await this.getBadConditionTrees();
      
      await this.setTreeList();
      await this.setTreeStatus();
      await this.setCampaignsList();
      await this.fetchGarden();
      this.treesByState = treeList.getNumberOfTreesByState(this.treeList, this.treeStatusList);
      this.treesByCampaign = treeList.getNumberOfTreesByCampaign(this.campaigns);
    } catch (error) {
      console.log('error', error)
    }
    this.loading = false;
  },

  methods: {
    ...mapActions('Tree', ['getBadConditionTrees', 'setTreeList', 'setTreeStatus']),
    ...mapActions('Campaign', ['setCampaignsList']),

    async fetchGarden(){
      this.garden = await garden()
    },

    async updateGarden(){
      if(this.gardenIntervalId !== null){ //turn of live
        clearInterval(this.gardenIntervalId);
        this.gardenIntervalId = null;
      } else {
        this.gardenIntervalId = setInterval(() =>{
          this.fetchGarden();
        }, 10000)
      }
    }
  },

  computed: {
    ...mapGetters('Tree', ['treeList', 'treeStatusList']),
    ...mapGetters('Campaign', ['campaigns']),

    empty(){
      return this.treesByState.length == 0 && this.treesByCampaign.length == 0 && this.trees.length == 0 && !this.loading;
    }
  },

  beforeDestroy(){
    clearInterval(this.gardenIntervalId);
  }
 
}
</script>
